import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/dist/themes/smoothness/jquery-ui.css';

$(function () {

  // 祝日を配列で確保
  var holidays = [];

  $(".calendar").datepicker({
    minDate: '+1d',
    dateFormat: 'yy/m/dd',
    beforeShowDay: function (date) {
      // 祝日の判定
      for (var i = 0; i < holidays.length; i++) {
        var htime = Date.parse(holidays[i]);
        var holiday = new Date();
        holiday.setTime(htime);

        if (holiday.getYear() == date.getYear() &&
          holiday.getMonth() == date.getMonth() &&
          holiday.getDate() == date.getDate()) {
          return [false, 'holiday'];
        }
      }
      // 平日
      return [true, ''];
    },
    onSelect: function (dateText, inst) {
      $(".calendar").blur();
    }
  });

});
