import 'jquery.cookie';
import { gsap } from 'gsap';
gsap.config({ nullTargetWarn: false, });

let path = location.pathname
if (path == "/") {
  $('body').addClass('stopscroll');
  $(window).on('load', function () {
    gsap.set(".mask", { y: 100 + '%' });
    setTimeout(function () {
      $('.op-text').fadeIn(600);
    }, 300);
    setTimeout(function () {
      $('.op-text').fadeOut(600);
    }, 2500);
    setTimeout(function () {
      $('#js-loader').fadeOut(600);
    }, 4000);
    $('body').removeClass('stopscroll');
    setTimeout(function () {
      gsap.to(".mask", { y: 0, duration: 1.5, ease: "power4.inOut" });
      //$('body').removeClass('stopscroll');
    }, 7000);
  })
  /*
  $(function () {
    if ($.cookie("access") == undefined) {
      $.cookie("access", "onece");
      $('body').addClass('stopscroll');
      $(window).on('load', function () {
        gsap.set(".mask", { y: 100 + '%' });
        setTimeout(function () {
          $('.op-text').fadeIn(600);
        }, 300);
        setTimeout(function () {
          $('.op-text').fadeOut(600);
        }, 2500);
        setTimeout(function () {
          $('#js-loader').fadeOut(600);
        }, 4000);

        setTimeout(function () {
          gsap.to(".mask", { y: 0, duration: 0.4, ease: "power4.Out" });
          $('body').removeClass('stopscroll');
        }, 5600)
      })



    } else {
      $('#js-loader').hide();
      $('body').removeClass('stopscroll');
      $('header').fadeIn(100);

    }

  });
  */
}
