import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

$('.service-single-casestudy-slides').slick({
  arrows: true,
  dots: false,
  infinite: true,
  autoplay: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1000,
    settings: {
      centerPadding: '45px',
      centerMode: true,
      slidesToShow: 1
    }
  }]
});

$('.recruit-principle-slides').slick({
  arrows: true,
  dots: true,
  infinite: true,
  autoplay: false,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});

$('.recruit-interview-slides').slick({
  arrows: true,
  dots: false,
  infinite: true,
  autoplay: false,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});


$('.top-ceremony-slides').slick({
  arrows: true,
  dots: true,
  infinite: false,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});

function sliderSetting() {
  let width = $(window).width();
  if (width <= 1000) {
    $('.footer-group-slides.slick-initialized').slick('unslick');
  } else {
    $('.footer-group-slides').not('.slick-initialized').slick({
      arrows: true,
      dots: true,
      infinite: true,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      //variableWidth: true,
    });
  }
}
sliderSetting();
$(window).on('resize', function () {
  sliderSetting();
});

