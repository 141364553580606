import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.registerPlugin(ScrollToPlugin);


/*
const wrapper = document.querySelector('#wrapper');
if (wrapper) {
  // gsap.registerPlugin(ScrollTrigger); // npm/yarnの際に必要
  const panels = gsap.utils.toArray('.panel');
  const wrapperWidth = wrapper.offsetWidth;

  //横スクロール開始

  gsap.to(panels, {
    xPercent: -100 * (panels.length - 1), // transformX
    ease: "none", // easingの設定
    scrollTrigger: { // scrollTrigger
      trigger: wrapper, // アニメーションの対象となる要素
      pin: true, // 要素を固定する
      scrub: 1, // スクロールとアニメーションを同期させる。数値で秒数の設定に
      snap: { // スナップスクロールにする
        snapTo: 1 / (panels.length - 1), // スナップで移動させる位置
        duration: { min: .2, max: .4 }, // スナップで移動する際の遅延時間
        ease: "none" // easing
      },
      end: () => "+=" + wrapperWidth // アニメーションの終了タイミング
    }
  })
}
*/

let path = location.pathname
if (path == "/") {
  let mm = gsap.matchMedia();
  mm.add("(min-width: 1001px)", () => {
    const wrap = document.querySelector(".ceremony-list");
    const panels = document.querySelectorAll(".ceremony-item");
    const num = panels.length;
    gsap.set(wrap, { height: num * 100 + "vh" });
    gsap.set(panels, { height: 100 / num + "vh" });

    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".trigger--1",
        start: 'top top+=100%',
        //pin: true,
        //markers: true,
        scrub: 1,
        // snap: { //キリの良い位置へ移動させる
        //   snapTo: 1 / (num - 1),
        //   duration: 0.2,
        // },
        toggleClass: { targets: ".ceremony-nav .b1", className: "is-active" },
        onEnter: () => {
          document.querySelector(".ceremony-item-1").classList.add("is-scroll");
        },
        onLeaveBack: () => {
          document.querySelector(".ceremony-item-1").classList.remove("is-scroll");
        }
      }
    });
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".trigger--2",
        start: 'top top',
        //pin: true,
        //markers: true,
        scrub: 1,
        // snap: {
        //   snapTo: 1 / (num - 1),
        //   duration: 0.2,
        // },
        toggleClass: { targets: ".ceremony-nav .b2", className: "is-active" },
        onEnter: () => {
          document.querySelector(".ceremony-item-2").classList.add("is-scroll");
        },
        onLeaveBack: () => {
          document.querySelector(".ceremony-item-2").classList.remove("is-scroll");
        }
      }
    });
    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".trigger--3",
        start: 'top top',
        snap: "labelsDirectional",
        //pin: true,
        //markers: true,
        // scrub: 1,
        // snap: { //キリの良い位置へ移動させる
        //   snapTo: 1 / (num - 1),
        //   duration: 0.2,
        // },
        toggleClass: { targets: ".ceremony-nav .b3", className: "is-active" },
        onEnter: () => {
          document.querySelector(".ceremony-item-3").classList.add("is-scroll");
        },
        onLeaveBack: () => {
          document.querySelector(".ceremony-item-3").classList.remove("is-scroll");
        }
      }

    });
    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: ".trigger--4",
        start: 'top top',
        snap: "labelsDirectional",
        //pin: true,
        //markers: true,
        scrub: 1,
        // snap: { //キリの良い位置へ移動させる
        //   snapTo: 1 / (num - 1),
        //   duration: 0.2,
        // },
        toggleClass: { targets: ".ceremony-nav .b4", className: "is-active" },
        onEnter: () => {
          document.querySelector(".ceremony-item-4").classList.add("is-scroll");
        },
        onLeaveBack: () => {
          document.querySelector(".ceremony-item-4").classList.remove("is-scroll");
        }
      }

    });
    const tl5 = gsap.timeline({
      scrollTrigger: {
        trigger: ".trigger--5",
        start: 'top top',
        snap: "labelsDirectional",
        //pin: true,
        //markers: true,
        scrub: 1,
        // snap: { //キリの良い位置へ移動させる
        //   snapTo: 1 / (num - 1),
        //   duration: 0.6,
        // },
        toggleClass: { targets: ".ceremony-nav .b5", className: "is-active" },
        onEnter: () => {
          document.querySelector(".ceremony-item-5").classList.add("is-scroll");
        },
        onLeaveBack: () => {
          document.querySelector(".ceremony-item-5").classList.remove("is-scroll");
        }
      }

    });

    $('.b1 span').on('click', function () {
      let pos = $('#ceremony1').offset();
      let newpos = pos.top + 1;
      gsap.to(window, { duration: .1, scrollTo: newpos });
    });
    $('.b2 span').on('click', function () {
      let pos = $('#ceremony2').offset();
      let newpos = pos.top + 1;
      gsap.to(window, { duration: .1, scrollTo: newpos });
    });
    $('.b3 span').on('click', function () {
      let pos = $('#ceremony3').offset();
      let newpos = pos.top + 1;
      gsap.to(window, { duration: .1, scrollTo: newpos });
    });
    $('.b4 span').on('click', function () {
      let pos = $('#ceremony4').offset();
      let newpos = pos.top + 1;
      gsap.to(window, { duration: .1, scrollTo: newpos });
    });
    $('.b5 span').on('click', function () {
      let pos = $('#ceremony5').offset();
      let newpos = pos.top + 1;
      gsap.to(window, { duration: .1, scrollTo: newpos });
    });

    $('.next-arrow').on('click', function () {
      if ($('.ceremony-nav .b1').hasClass('is-active')) {
        let pos = $('#ceremony2').offset();
        let newpos = pos.top + 1;
        gsap.to(window, { duration: .1, scrollTo: newpos });
      } else if ($('.ceremony-nav .b2').hasClass('is-active')) {
        let pos = $('#ceremony3').offset();
        let newpos = pos.top + 1;
        gsap.to(window, { duration: .1, scrollTo: newpos });
      } else if ($('.ceremony-nav .b3').hasClass('is-active')) {
        let pos = $('#ceremony4').offset();
        let newpos = pos.top + 1;
        gsap.to(window, { duration: .1, scrollTo: newpos });
      } else if ($('.ceremony-nav .b4').hasClass('is-active')) {
        let pos = $('#ceremony5').offset();
        let newpos = pos.top + 1;
        gsap.to(window, { duration: .1, scrollTo: newpos });
      } else if ($('.ceremony-nav .b5').hasClass('is-active')) {
        let pos = $('#ceremony1').offset();
        let newpos = pos.top + 1;
        gsap.to(window, { duration: .1, scrollTo: newpos });
      }

    })
  })
}
