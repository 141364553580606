$(function () {
  let $tab = $('.tab-trigger');
  $tab.on('click', function () {
    let index = $('.tab-trigger').index(this);
    let nth = index + 1;
    if (nth > 5) {
      nth = index - 4;
    }
    console.log(nth);
    $('.tab-trigger').removeClass('is-active');
    $('.tab-trigger:nth-child(' + nth + ')').addClass('is-active');
    $(this).addClass('is-active');

    $('.tab-target').removeClass('is-active');
    $('.tab-target:nth-child(' + nth + ')').addClass('is-active');
    return false;
  });


});
