import 'jquery-inview';

$('.inview').on('inview', function () {
  $(this).addClass('fadeInUp');
});
$('.inviewL').on('inview', function () {
  $(this).addClass('fadeInLeft');
});
$('.inviewR').on('inview', function () {
  $(this).addClass('fadeInRight');
});

